import React, { useCallback } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { useSmartLinkLifecycleAnalytics } from '@atlaskit/link-analytics';

import { usePageSpaceKey } from '@confluence/page-context';
import { useBooleanFeatureFlag } from '@confluence/session-data';

import { SpaceShortcutsSpaceNavigationQuery } from './SpaceShortcutsSpaceNavigationQuery.graphql';
import { ExperimentalUpdateShortcuts } from './ExperimentalUpdateShortcuts.experimentalgraphql';
import type {
	ExperimentalUpdateShortcuts as UpdateShortcutMutationData,
	ExperimentalUpdateShortcutsVariables as UpdateShortcutMutationVars,
} from './__types__/ExperimentalUpdateShortcuts';
import type { SpaceShortcutsSpaceNavigationQuery_spaceSidebarLinks_quick as SpaceNavigationQueryShortcutsType } from './__types__/SpaceShortcutsSpaceNavigationQuery';

const i18n = defineMessages({
	reorderShortcutSuccessFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.reorder-success-flag.title',
		defaultMessage: `The shortcut was reordered`,
		description: 'Title for the flag that shows on successful reordering of shortcuts',
	},
	reorderShortcutErrorFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.reorder-error-flag.title',
		defaultMessage: `The shortcut could not be reordered`,
		description: 'Title for the flag that shows on error of reordering of shortcuts',
	},
	reorderShortcutErrorFlagDescription: {
		id: 'space-shortcuts.space-space-shortcuts.reorder-error-flag.description',
		defaultMessage: `There was an error moving the shortcut. Refresh the page and try again.`, //TODO: if possible, return reason for failure, to follow page tree errors
		description: 'Description for the flag that shows on error of reordering of shortcuts',
	},
	removeShortcutSuccessFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.remove-success-flag.title',
		defaultMessage: `The shortcut was removed`,
		description: 'Title for the flag that shows on successful removal of shortcuts',
	},
	removeShortcutErrorFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.remove-error-flag.title',
		defaultMessage: `The shortcut could not be removed`,
		description: 'Title for the flag that shows on error of removal of shortcuts',
	},
	removeShortcutErrorFlagDescription: {
		id: 'space-shortcuts.space-space-shortcuts.remove-error-flag.description',
		defaultMessage: `There was an error removing the shortcut. Refresh the page and try again.`, //TODO: if possible, return reason for failure, to follow page tree errors
		description: 'Description for the flag that shows on error of removal of shortcut',
	},
	addShortcutSuccessFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.add-success-flag.title',
		defaultMessage: 'New shortcut added',
		description: 'Title for the flag that shows on successful creation of a shortcut',
	},
	addShortcutErrorFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.add-error-flag.title',
		defaultMessage: 'The shortcut could not be added',
		description: 'Title for the flag that shows on error of creating a shortcut',
	},
	addShortcutErrorFlagDescription: {
		id: 'space-shortcuts.space-space-shortcuts.add-error-flag.description',
		defaultMessage: `There was an error creating the shortcut. Refresh the page and try again.`, //TODO: if possible, return reason for failure, to follow page tree errors
		description: 'Description for the flag that shows on error of creating a shortcut',
	},
	editShortcutSuccessFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.edit-success-flag.title',
		defaultMessage: 'The shortcut was edited',
		description: 'Title for the flag that shows on successful edit of a shortcut',
	},
	editShortcutErrorFlagTitle: {
		id: 'space-shortcuts.space-space-shortcuts.edit-error-flag.title',
		defaultMessage: 'The shortcut could not be edited',
		description: 'Title for the flag that shows on error of editing a shortcut',
	},
	editShortcutErrorFlagDescription: {
		id: 'space-shortcuts.space-space-shortcuts.edit-error-flag.description',
		defaultMessage: `There was an error updating the shortcut. Refresh the page and try again.`,
		description: 'Description for the flag that shows on error of editing a shortcut',
	},
	flagUndoAction: {
		id: 'space-shortcuts.space-space-shortcuts.undo-action',
		defaultMessage: `Undo`,
		description: 'Text for the undo action inside flag',
	},
});

type DraggableLocation = {
	index: number;
};

type AddShortcutParamsType = {
	url: string | null;
	title: string | null;
};

type EditShortcutParamsType = {
	id: string | null;
	url: string | null;
	title: string | null;
};

const useUpdateShortcuts = (flags, ref?) => {
	const [spaceKey] = usePageSpaceKey();
	const [updateShortcutsMutation] = useMutation<
		UpdateShortcutMutationData,
		UpdateShortcutMutationVars
	>(ExperimentalUpdateShortcuts);
	const isMigrateShortcutsEnabled = useBooleanFeatureFlag(
		'confluence.frontend.migrate-shortcuts-to-smart-links-in-tree',
	);

	return useCallback(
		(
			newLinks: any,
			changedItem: any,
			successCopyTitle: any,
			errorCopyTitle: any,
			errorCopyDesc: any,
			showFlag = true,
			oldItem?: any,
		) => {
			const mutationPayload = newLinks.map((link) => {
				if (link.hasOwnProperty('id') && link.id && !oldItem) {
					return { id: link.id };
				}
				return {
					id: link.id,
					title: link.title,
					url: link.url,
					resourceType: link.resourceType,
					resourceId: link.resourceId,
				};
			});

			const optimisticUpdate = newLinks.map((link) => {
				return {
					// must remove id to prevent "Could not find link ID" errors from server
					id: link.id && link.id.startsWith('cached-') ? null : link.id,
					title: link.title,
					url: link.url,
					resourceType: link.resourceType,
					resourceId: link.resourceId,
				};
			});

			return updateShortcutsMutation({
				variables: {
					spaceKey: spaceKey || '',
					quickLinks: mutationPayload,
				},
				optimisticResponse: {
					experimentalUpdateShortcutLinks: {
						quickLinks: optimisticUpdate,
						__typename: 'ExperimentalQuickLinks',
					},
				},
				update: (cache, { data }) => {
					newLinks = newLinks.map((link) =>
						// provides an id to be used as the key for the Draggable component in ShortcutsDragContainer
						// otherwise it will use index and re-render unnecessarily
						link.id === null ? { ...link, id: `cached-${changedItem.id}` } : link,
					);

					const massagedData = data?.experimentalUpdateShortcutLinks?.quickLinks
						? data?.experimentalUpdateShortcutLinks?.quickLinks.map((link) => {
								return { ...link, __typename: 'SpaceSidebarLink' };
							})
						: newLinks;

					cache.writeQuery({
						query: SpaceShortcutsSpaceNavigationQuery,
						data: {
							spaceSidebarLinks: {
								quick: massagedData,
								__typename: 'SpaceSidebarLinks',
							},
						},
						variables: { spaceKey, isLicensed: true },
					});
				},
			})
				.then(() => {
					// showFlag parameter is to prevent the flag from showing again when pressing "undo"
					if (showFlag) {
						changedItem = ref?.current?.peek()?.lastDeletedItem || changedItem;
						const id = 'shortcutsFlag';
						void flags.showSuccessFlag({
							id,
							title: <FormattedMessage {...successCopyTitle} />,
							actions:
								ref && !isMigrateShortcutsEnabled
									? [
											{
												content: <FormattedMessage {...i18n.flagUndoAction} />,
												onClick: () => {
													const reverseMovement = ref.current.pop();
													if (reverseMovement) {
														const { undo: undoFn } = reverseMovement;

														if (undoFn) {
															undoFn();
														}
													}
													void flags.hideFlag(id);
												},
											},
										]
									: [],
						});

						if (ref) {
							const historyEntry = ref.current.peek();
							if (historyEntry) {
								historyEntry.flagId = id;
							}
						}
					}
				})
				.catch(() => {
					void flags.showErrorFlag({
						title: <FormattedMessage {...errorCopyTitle} />,
						description: (
							<FormattedMessage
								{...errorCopyDesc}
								values={{ linkTitle: changedItem.title || changedItem.url }}
							/>
						),
					});
				});
		},
		[updateShortcutsMutation, spaceKey, flags, ref, isMigrateShortcutsEnabled],
	);
};

const updateShortcutsWithNewItem = (newItem, rawLinks, updateShortcuts) => {
	rawLinks.reverse();
	const reorderedLinks = rawLinks.map((link) => ({
		...link,
		position: link.position++,
	}));
	const newLinks = [
		...reorderedLinks,
		{
			...newItem,
			id: null,
			position: 0,
			styleClass: 'external_link',
			__typename: 'SpaceSidebarLink',
		},
	];
	newLinks.reverse();
	void updateShortcuts(
		newLinks,
		newItem,
		i18n.addShortcutSuccessFlagTitle,
		i18n.addShortcutErrorFlagTitle,
		i18n.addShortcutErrorFlagDescription,
	);
};

export const useAddShortcut = (rawLinks, flags, ref) => {
	const updateShortcuts = useUpdateShortcuts(flags, ref);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { linkDeleted } = useSmartLinkLifecycleAnalytics();

	const addShortcut = useCallback(
		(newItem: AddShortcutParamsType) => {
			updateShortcutsWithNewItem(newItem, rawLinks, updateShortcuts);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'add',
					actionSubject: 'spaceShortcuts',
					source: 'spaceShortcutNavigation',
				},
			}).fire();
		},
		[rawLinks, updateShortcuts, createAnalyticsEvent],
	);

	const undoAddShortcut = useCallback(
		(newItem: SpaceNavigationQueryShortcutsType) => {
			const newLinks = rawLinks.filter((item) => item.id !== newItem.id);
			newLinks.reverse();
			void updateShortcuts(
				newLinks,
				newItem,
				i18n.removeShortcutSuccessFlagTitle,
				i18n.removeShortcutErrorFlagTitle,
				i18n.removeShortcutErrorFlagDescription,
				false,
			);
			const analyticsEvent = createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'undo',
					actionSubject: 'add',
					actionSubjectId: 'spaceShortcuts',
					source: 'spaceShortcutNavigation',
				},
			});
			analyticsEvent.fire();
			if (newItem.url) linkDeleted({ url: newItem.url }, analyticsEvent);
		},
		[rawLinks, updateShortcuts, createAnalyticsEvent, linkDeleted],
	);

	return { addShortcut, undoAddShortcut };
};

export const useAppendShortcut = (flags) => {
	const updateShortcuts = useUpdateShortcuts(flags);
	const client = useApolloClient();

	const appendShortcut = (spaceKey: string, contentId: string) => {
		const newItem = {
			url: `${window.location.origin}/wiki/spaces/${spaceKey}/pages/${contentId}`,
		};

		client
			.query({
				query: SpaceShortcutsSpaceNavigationQuery,
				variables: { spaceKey },
			})
			.then(({ data }) => {
				updateShortcutsWithNewItem(newItem, data.spaceSidebarLinks.quick, updateShortcuts);
			})
			.catch(() => {
				void flags.showErrorFlag({
					title: <FormattedMessage {...i18n.addShortcutErrorFlagTitle} />,
					description: <FormattedMessage {...i18n.addShortcutErrorFlagDescription} />,
				});
			});
	};

	return { appendShortcut };
};

export const useEditShortcut = (rawLinks, flags, spaceId) => {
	const updateShortcuts = useUpdateShortcuts(flags);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	return useCallback(
		(editItem: EditShortcutParamsType) => {
			// create new quick link list with edited item
			const newLinks: any = Array.from(rawLinks).reverse();
			const editLinkPosition = newLinks.findIndex((item) => item.id === editItem.id);
			const oldItem = newLinks[editLinkPosition];
			newLinks.splice(editLinkPosition, 1, editItem);

			// reverse the link list in preparation of storing the data with the mutation in reverse order
			newLinks.reverse();
			void updateShortcuts(
				newLinks,
				editItem,
				i18n.editShortcutSuccessFlagTitle,
				i18n.editShortcutErrorFlagTitle,
				i18n.editShortcutErrorFlagDescription,
				true,
				oldItem,
			);
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'updated',
					actionSubject: 'spaceShortcuts',
					source: 'space-shortcut-quick-action',
					containerId: spaceId,
					containerType: 'space',
				},
			}).fire();
		},
		[rawLinks, updateShortcuts, createAnalyticsEvent, spaceId],
	);
};

export const useDeleteShortcut = (rawLinks, flags, ref) => {
	const updateShortcuts = useUpdateShortcuts(flags, ref);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { linkCreated, linkDeleted } = useSmartLinkLifecycleAnalytics();

	const deleteShortcut = useCallback(
		(removedItem: SpaceNavigationQueryShortcutsType) => {
			const newLinks = rawLinks.filter((item) => item.id !== removedItem.id);
			void updateShortcuts(
				newLinks,
				removedItem,
				i18n.removeShortcutSuccessFlagTitle,
				i18n.removeShortcutErrorFlagTitle,
				i18n.removeShortcutErrorFlagDescription,
			);
			const analyticsEvent = createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'deleted',
					actionSubject: 'spaceShortcuts',
					source: 'space-shortcut-quick-action',
				},
			});
			analyticsEvent.fire();
			if (removedItem.url) linkDeleted({ url: removedItem.url }, analyticsEvent);
		},
		[rawLinks, updateShortcuts, createAnalyticsEvent, linkDeleted],
	);

	const undoDeleteShortcut = useCallback(
		(removedItem: SpaceNavigationQueryShortcutsType) => {
			// must remove id to prevent "Could not find link ID" errors from server
			const newLinks = rawLinks.map((item) =>
				item.id === removedItem.id ? { ...item, id: null } : item,
			);
			void updateShortcuts(
				newLinks,
				removedItem,
				i18n.removeShortcutSuccessFlagTitle,
				i18n.removeShortcutErrorFlagTitle,
				i18n.removeShortcutErrorFlagDescription,
				false,
			);
			const analyticsEvent = createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'undo',
					actionSubject: 'delete',
					actionSubjectId: 'spaceShortcuts',
					source: 'space-shortcut-quick-action',
				},
			});
			analyticsEvent.fire();
			if (removedItem.url) linkCreated({ url: removedItem.url }, analyticsEvent);
		},
		[rawLinks, updateShortcuts, createAnalyticsEvent, linkCreated],
	);

	return { deleteShortcut, undoDeleteShortcut };
};

export const useMoveShortcut = (rawLinks, flags, ref) => {
	const updateShortcuts = useUpdateShortcuts(flags, ref);
	const moveShortcut = useCallback(
		(source: DraggableLocation, destination: DraggableLocation) => {
			// create new quick link list with rearranged items
			const newLinks = Array.from(rawLinks).reverse();
			const [removedItem] = newLinks.splice(source.index, 1);
			newLinks.splice(destination.index, 0, removedItem);

			// reverse the link list in preparation of storing the data with the mutation in reverse order
			newLinks.reverse();
			void updateShortcuts(
				newLinks,
				removedItem,
				i18n.reorderShortcutSuccessFlagTitle,
				i18n.reorderShortcutErrorFlagTitle,
				i18n.reorderShortcutErrorFlagDescription,
			);
		},
		[rawLinks, updateShortcuts],
	);

	const undoMoveShortcut = useCallback(
		(source: any) => {
			const newLinks = Array.from(rawLinks).reverse();
			const [removedItem] = newLinks.slice(source.index, 1);
			newLinks.reverse();
			void updateShortcuts(
				newLinks,
				removedItem,
				i18n.reorderShortcutSuccessFlagTitle,
				i18n.reorderShortcutErrorFlagTitle,
				i18n.reorderShortcutErrorFlagDescription,
				false,
			);
		},
		[rawLinks, updateShortcuts],
	);

	return { moveShortcut, undoMoveShortcut };
};
